// ForgotPassword.js
import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "./logo/logo.png";
import { Spin } from "antd";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => setEmail(e.target.value);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API}/forgot-password`, { email });
      toast.success("ระบบได้ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปยังอีเมลของคุณแล้ว");
      navigate("/login");
    } catch (err) {
      toast.error("ไม่สามารถส่งอีเมลสำหรับรีเซ็ตรหัสผ่านได้ โปรดลองอีกครั้ง");
    }
  };

  return (
    <div className="container p-5">
      <div className="row justify-content-center">
        <div className="col-md-6"
          style={{
            boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
            padding: "2rem",
            borderRadius: "8px",
          }}>
          {loading ? (
            <h1>
              Loading...
              <Spin />
            </h1>
          ) : (
            <div className="text-center mb-4">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
              <h4>รีเซ็ตรหัสผ่านของคุณ</h4>
            </div>
          )}
          {/* <h4>Forgot Password</h4> */}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              {/* <label>กรูณากรอก Email ที่ใช้สมัครสามชิก</label> */}
              <div className="mb-3 text-center">
                <p
                  style={{
                    fontSize: "14px", // ขนาดฟอนต์
                    color: "#555", // สีของข้อความ
                    textAlign: "left", // ชิดซ้าย
                    lineHeight: "1.6", // ระยะห่างระหว่างบรรทัด
                    textIndent: "2em", // ย่อหน้าประมาณ 2 ช่อง
                  }}
                >
                  *โปรดกรอกอีเมลที่คุณใช้สมัครสมาชิกไว้ในช่องด้านล่าง
                  ระบบจะส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปยังอีเมลของคุณ
                  หลังจากที่คุณได้รับลิงก์ ให้คลิกเพื่อตั้งค่ารหัสผ่านใหม่
                </p>
              </div>
              <input
                className="form-control"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleChange}
              />
            </div>


            <div className="d-flex justify-content-center">
              <button className="btn mt-3"
                style={{ backgroundColor: "#FF70A6", color: "#fff" }}
                >
                {loading ? "กำลังส่ง..." : "ยืนยัน"}</button>
            </div>

          </form>
        </div>

      </div>

    </div>
  );
};

export default ForgotPassword;


