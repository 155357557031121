import React from "react";
import Resizer from "react-image-file-resizer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar, Badge } from "antd";

const FileUploadProfile = ({ profileImage, setProfileImage, loading, setLoading }) => {
  const { user } = useSelector((state) => ({ ...state })); // ดึงข้อมูล user จาก Redux store
  
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true); // ตั้งสถานะกำลังโหลด

      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        async (uri) => {
          try {
            const res = await axios.post(
              `${process.env.REACT_APP_API}/images-profile`,
              { image: uri },
              {
                headers: {
                  authtoken: user?.token, // ใช้ token สำหรับการตรวจสอบสิทธิ์
                },
              }
            );
            setLoading(false); // ยกเลิกสถานะกำลังโหลด
            setProfileImage(res.data); // บันทึกข้อมูลรูปภาพที่อัปโหลด
            toast.success("Image uploaded successfully");
          } catch (err) {
            setLoading(false); // ยกเลิกสถานะกำลังโหลด
            console.error(err);
            toast.error("Image upload failed");
          }
        },
        "base64"
      );
    }
  };

  const handleRemoveImage = async () => {
    if (profileImage && profileImage.public_id) {
      setLoading(true); // ตั้งสถานะกำลังโหลด
      try {
        await axios.post(
          `${process.env.REACT_APP_API}/delete-profile`,
          { public_id: profileImage.public_id },
          {
            headers: {
              authtoken: user?.token, // ใช้ token สำหรับการตรวจสอบสิทธิ์
            },
          }
        );
        setLoading(false); // ยกเลิกสถานะกำลังโหลด
        setProfileImage(null); // ลบรูปภาพ
        toast.success("Image removed successfully");
      } catch (err) {
        setLoading(false); // ยกเลิกสถานะกำลังโหลด
        console.error(err);
        toast.error("Image remove failed");
      }
    }
  };

  return (
    <div>
      {profileImage && profileImage.url ? (
        <Badge
          count="X"
          onClick={handleRemoveImage}
          style={{ cursor: "pointer" }}
        >
          <Avatar src={profileImage.url} size={100} shape="circle" />
        </Badge>
      ) : (
        <label>
          <input
            type="file"
            accept="image/*"
            onChange={handleChangeFile}
            hidden
          />
          <div
            style={{
              width: "100px",
              height: "100px",
              border: "1px dashed #d9d9d9",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            Upload
          </div>
        </label>
      )}
    </div>
  );
};

export default FileUploadProfile;
