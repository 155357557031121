import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons"; // ไอคอนแว่นขยาย

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useSelector((state) => ({ ...state }));
  const { text } = search;

  const [isSearchVisible, setSearchVisible] = useState(false);

  const handleChange = (e) => {
    dispatch({
      type: "SEARCH_QUERY",
      payload: { text: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("./shop?" + text);
  };

  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
  };

  return (
    <div className="search-container">
      {/* เพิ่มข้อความ "ค้าหาสินค้า" ที่คลิกได้ */}
      {!isSearchVisible && (
        <span className="search-text" onClick={toggleSearch} style={{ color: "#939392", fontSize: "12px",marginTop: "20px",textAlign: "center", }}>
          ค้าหาสินค้า
        </span>
      )}
      {/* ไอคอนแว่นขยาย */}
      <SearchOutlined onClick={toggleSearch} className="search-icon" style={{ color: "#000000", fontSize: "20px", }} />
      
      {/* ช่องค้นหาที่จะเปิดเมื่อคลิก */}
      {isSearchVisible && (
        <form onSubmit={handleSubmit} className="search-form">
          <input
            onChange={handleChange}
            type="search"
            className="form-control search-input"
            value={text}
            placeholder="Search..."
          />
        </form>
      )}
    </div>
  );
};

export default Search;
