import React from "react";
import NewProduct from "../../home/NewProduct";
import "./ShopService.css";
import VitaminsLeDream from "../../home/VitaminsLeDream";
import HotUniqare from "../../home/HotUniqare";
import Regenerative from "../../home/Regenerative";
import Aesthetics from "../../home/Aesthetics";

const ShopService = () => {
  return (
    <div className="container">
      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading1-container">
            <div className="custom-heading blinking-boxss">
              {/* Rehabilitaion */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <HotUniqare />
      </div>
      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading2-container">
            <div className="custom-heading blinking-boxss">
              {/* Aesthetics */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <Aesthetics />
      </div>
      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-headingIV-container">
            <div className="custom-heading blinking-boxss">
              {/* IV */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <VitaminsLeDream />
      </div>

      {/* Regen*/}
      <div className="box-shadow mt-1">
        <div className="row mt-1">
          <div className="col-12 custom-headingRegen-container">
            <div className="custom-heading blinking-boxss">
              
            </div>
          </div>
        </div>
        <Regenerative />
      </div>

      {/* Medical Spa */}
      {/* <div className="box-shadow mt-5">
        <div className="row mt-1">
          <div className="col-12 custom-heading3-container">
            <div className="custom-heading blinking-boxss">
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}


      {/* Health Check-up */}
      {/* <div className="box-shadow mt-1">
        <div className="row mt-1">
          <div className="col-12 custom-heading4-container">
            <div className="custom-heading blinking-boxss">
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}

      

      {/* Fitness */}
      {/* <div className="box-shadow mt-1">
        
        <div className="row mt-1">
          <div className="col-12 custom-heading5-container">
            <div className="custom-heading blinking-boxss">
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}

      {/* Mental Wellness & Therapy */}
      {/* <div className="box-shadow mt-1">
        <div className="row mt-1">
          <div className="col-12 custom-heading6-container">
            <div className="custom-heading blinking-boxss">
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}

      {/* Wellness Tourism */}
      {/* <div className="box-shadow mt-1">
        <div className="row mt-1">
          <div className="col-12 custom-heading7-container">
            <div className="custom-heading blinking-boxss">
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}

      {/* Care U Home */}
      {/* <div className="box-shadow mt-1">
        <div className="row mt-1">
          <div className="col-12 custom-heading8-container">
            <div className="custom-heading blinking-boxss"> 
            </div>
          </div>
        </div>
        <NewProduct />
      </div> */}
      
      
    </div>
  );
};

export default ShopService;
