import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // นำเข้า useNavigate
import { listProductBy } from "../function/product";
import ProductCardHome from "../card/ProductCardHome";
import ProductCard from "../card/ProductCard";
import LoadingCard from "../card/LoadingCard";
import "./AllProduct.css";

const AllProduct = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate(); // สร้าง navigate object

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    listProductBy("createdAt", "desc", 50)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="container allproduct-container">
      {loading ? (
        <LoadingCard count={3} />
      ) : (
        <>
          <div className="row">
            {products.slice(0, 8).map((item, index) => (
              <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-3 mb-4">
                <ProductCard product={item} />
              </div>
            ))}
          </div>
          {products.length > 8 && (
            <div className="text-center">
              <button
                className="btn"
                onClick={() => navigate("/shop")} // ใช้ navigate เพื่อไปที่ /shop
                style={{ backgroundColor: "#FF70A6", color: "#fff" }}
              >
                ดูเพิ่มเติม
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllProduct;
