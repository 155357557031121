// Cancel.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>การชำระเงินถูกยกเลิก</h1>
      <p>ขออภัยที่เกิดปัญหาการชำระเงิน กรุณาลองใหม่อีกครั้ง</p>
      <button onClick={() => navigate("/checkout")} style={{ padding: '10px 20px', backgroundColor: '#ff4d4d', color: '#fff', border: 'none', borderRadius: '5px' }}>
        กลับไปที่หน้าชำระเงิน
      </button>
    </div>
  );
};

export default Cancel;
