// import axios from 'axios';




// // ฟังก์ชันเพิ่มรีวิว
// export const addReview = async (authtoken,productId, rating, comment, ) => {
//   try {
//     const response = await axios.post(process.env.REACT_APP_API + '/addreviews', 
//       { 
//         productId, 
//         rating, 
//         comment 
//       }, 
//       { 
//         headers: { 
//           authtoken,
//         }
//       }
//     );
//     return response.data; // ส่งข้อมูลรีวิวที่เพิ่ม
//   } catch (error) {
//     console.error("Error adding review:", error);
//     throw error; // โยนข้อผิดพลาดให้ในที่ที่เรียกใช้งาน
//   }
// };

import axios from "axios";



export const addReview = async (authtoken, reviewData) => {
  try {
    // const authtoken = localStorage.getItem("authtoken"); 

    // ส่งคำขอไปยัง API
    const response = await axios.post(
      `${process.env.REACT_APP_API}/review`,
      reviewData,
      {
        headers: {
          authtoken, // ส่ง token สำหรับการตรวจสอบสิทธิ์
        },
      }
    );
    return response.data; // ส่งคืนข้อมูลจาก API
  } catch (error) {
    console.error("Error adding review:", error); // แสดงข้อผิดพลาดใน console
    throw error; // ส่งข้อผิดพลาดกลับไปยังที่เรียกใช้งาน
  }
};

// export const addReview = async (authtoken, reviewData) => {
//   try {
    
//     const response = await axios.post(
//       `${process.env.REACT_APP_API}/review`,
//       reviewData,
//       {
//         headers: {
//           authtoken, 
//         },
//       }
//     );
//     return response.data; 
//   } catch (error) {
//     console.error("Error adding review:", error); 
//     throw error; 
//   }
// };

export const getProductReviews = async (productId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/reviews/${productId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching product reviews:", error);
    throw error;
  }
};


