import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ReactTypingEffect from "react-typing-effect";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partner.css'; // นำเข้าไฟล์ CSS ของคุณ

const Partner = () => {
  const [key, setKey] = useState('conditions'); // ตั้งค่าแท็บเริ่มต้น

  return (
    <div className='container'>
      <div className='row text-center mt-2'>
        <div className='row mt-4'>
          <ReactTypingEffect
                        text={["ร่วมเป็นพันธมิตรกับ 360 Healthy Shop"]}
                        speed={100}
                        eraseSpeed={50}
                        eraseDelay={2000}
                        typingDelay={500}
                        style={{ color: "#FF1493", fontSize: "30px" }}
                      />
        </div>
        <div className="col-12 custom-headPart-container mt-1"></div>
      </div>

      {/* เพิ่มข้อความก่อนแท็บ */}
      <div className="row justify-content-center mt-4">
        <div className="col-12 text-center">
          <h3 className="partner-heading">
            สำหรับเจ้าของแบรนด์สินค้า/บริการ ที่ต้องการขยายฐานลูกค้ารักสุขภาพ
          </h3>
          <p className="partner-description">
            หากคุณมีสินค้า/บริการ ด้านการแพทย์/สุขภาพที่ดี มีมาตราฐาน
            และมองหาโอกาสขยายธุรกิจของคุณให้เติบโตยั่งยืน
            เราคือพันธมิตรที่พร้อมเดินไปด้วยกัน
          </p>
        </div>
      </div>

      <div className="row justify-content-center mt-4">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="text-center">
          <Tab eventKey="conditions" title="พันธมิตรที่เรามองหา" className="tab-background">
            <h4>พันธมิตรที่เรามองหา</h4>
            
            <ul>
              <li>ผู้ที่มีสินค้า/บริการที่มีคุณภาพ</li>
              <li>ผู้ประกอบการที่มีความพร้อมในการเติบโต</li>
              <li>ธุรกิจที่มองหาการเติบโตระยะยาว</li>
              <li>ผู้ที่มุ่งมั่นในการส่งมอบสิ่งที่ดีที่สุดให้ลูกค้า</li>
            </ul>
          </Tab>
          
          <Tab eventKey="steps" title="ประโยชน์ที่คุณจะได้รับ" className="tab-background">
            <h4>ประโยชน์ที่คุณจะได้รับ</h4>
            <p>การเป็นพันธมิตรกับเราไม่ได้หมายถึงแค่การขายสินค้า แต่คือการเติบโตไปด้วยกันอย่างยั่งยืน เราพร้อมช่วยสนับสนุนธุรกิจของคุณในทุกด้าน เพื่อให้คุณได้รับผลลัพธ์ที่ดีที่สุด</p>
            <ul>
              <li>การโปรโมทแบรนด์อย่างมืออาชีพ</li>
              <li>รายได้ที่เพิ่มขึ้น</li>
              <li>การเชื่อมต่อเครือข่ายธุรกิจ</li>
            </ul>
          </Tab>
          
          <Tab eventKey="news" title="ขั้นตอนการสมัคร" className="tab-background">
            <h4>ขั้นตอนการสมัคร</h4>
            <ol>
              <li><strong>กรอกใบสมัครออนไลน์:</strong> กรอกข้อมูลเกี่ยวกับธุรกิจและสินค้าของคุณในแบบฟอร์มออนไลน์ที่ใช้งานง่าย เราต้องการทราบข้อมูลเบื้องต้นเกี่ยวกับผลิตภัณฑ์และเป้าหมายของคุณ</li>
              <li><strong>การตรวจสอบและคัดเลือก:</strong> ทีมงานของเราจะตรวจสอบข้อมูลของคุณอย่างละเอียด เพื่อให้มั่นใจว่าสินค้าหรือบริการของคุณสอดคล้องกับตลาดและคุณค่าที่เราต้องการ จากนั้นเราจะติดต่อกลับภายใน 3-5 วันทำการ</li>
              <li><strong>เซ็นสัญญาและเริ่มต้นร่วมงาน:</strong> เมื่อได้รับการอนุมัติ คุณจะได้พบกับทีมงานที่พร้อมช่วยเหลือในการวางแผนการตลาด การโปรโมทสินค้า และการตั้งค่าต่าง ๆ เพื่อเริ่มต้นความร่วมมืออย่างราบรื่น</li>
              <li><strong>เปิดตัวสินค้า/บริการของคุณ:</strong> เราจะช่วยเปิดตัวสินค้า/บริการของคุณบนแพลตฟอร์มของเรา พร้อมแผนการตลาดที่ทรงพลัง เพื่อดึงดูดลูกค้าและสร้างผลลัพธ์ที่จับต้องได้</li>
            </ol>
            <h5>สมัครเลยวันนี้!</h5>
            <p>สมัครเป็นสมาชิกกับ 360 Partnership อย่ารอช้า โอกาสสำคัญในการเติบโตทางธุรกิจของคุณอยู่ตรงหน้าแล้ว คลิกปุ่มด้านล่างเพื่อเริ่มต้นกระบวนการสมัครทันที!</p>
            <button className="btn custom-btn">สมัครเป็นพันธมิตรทันที</button>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Partner;
