import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlined } from "@ant-design/icons";
import "./ProductTableInCart.css";

const ProductTableInCart = ({ item }) => {
  const dispatch = useDispatch();

  const handleChangeCount = (e) => {
    const count = e.target.value < 1 ? 1 : e.target.value;
    if (count > item.quantity) {
      toast.error("Max available Quantity: " + item.quantity);
      return;
    }

    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    cart.map((product, i) => {
      if (product._id === item._id) {
        cart[i].count = count;
      }
    });

    localStorage.setItem("cart", JSON.stringify(cart));
    dispatch({
      type: "ADD_TO_CART",
      payload: cart,
    });
  };

  const handleRemove = () => {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }

    cart.map((product, i) => {
      if (product._id === item._id) {
        cart.splice(i, 1);
      }
    });

    localStorage.setItem("cart", JSON.stringify(cart));
    dispatch({
      type: "ADD_TO_CART",
      payload: cart,
    });
  };

  return (
    <tbody>
      <tr>
        <td>
          <img src={item.images[0].url} width="100" alt={item.title} />
        </td>
        <td>{item.title}</td>
        <td>
          <div className="price-container">
            {item.discountedPrice ? (
              <>
                <span className="original-price">{item.price.toLocaleString()} บาท</span>
                <span className="discounted-price">{item.discountedPrice.toLocaleString()} บาท</span>
              </>
            ) : (
              <span className="normal-price">{item.price.toLocaleString()} บาท</span>
            )}
          </div>
        </td>
        <td>
          <div className="count-container">
            <button
              className="btn btn-outline-secondary count-button"
              onClick={() =>
                handleChangeCount({ target: { value: item.count - 1 } })
              }
              disabled={item.count <= 1}
            >
              -
            </button>
            <input
              type="number"
              value={item.count}
              className="count-input"
              onChange={handleChangeCount}
            />
            <button
              className="btn btn-outline-secondary count-button"
              onClick={() =>
                handleChangeCount({ target: { value: item.count + 1 } })
              }
              disabled={item.count >= item.quantity}
            >
              +
            </button>
          </div>
        </td>
        <td>
          <button className="btn btn-danger" onClick={handleRemove}>
            <DeleteOutlined />
          </button>
        </td>
      </tr>
    </tbody>
  );
};

export default ProductTableInCart;
