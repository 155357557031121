import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { getSubCategoryProducts } from "../../function/MunuItem";
import { EyeOutlined, ShoppingCartOutlined } from "@ant-design/icons"; // นำเข้าไอคอน
import { useDispatch } from "react-redux";
import _ from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ProductDetails.css";

const ProductDetails = () => {
  const { subId } = useParams();
  const [products, setProducts] = useState([]);
  const [websiteLink, setWebsiteLink] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    loadProducts();
  }, [subId]);

  const loadProducts = async () => {
    try {
      const token = localStorage.token;
      const res = await getSubCategoryProducts(token, subId);
      setProducts(res.data.products);
      setWebsiteLink(res.data.websiteLink || "");
      setLoading(false);
    } catch (err) {
      console.error("Error loading products:", err);
      setLoading(false);
    }
  };

  const handleAddToCart = (productmenu) => {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
  
    // กำหนดให้เป็น 'productmenu' เพราะสินค้าทั้งหมดในหน้านี้เป็น productmenu
    const item = {
      _id: productmenu._id,   // ใช้ _id ของ productmenu
      title: productmenu.title || productmenu.name, // ใช้ title ของ productmenu
      description: productmenu.description,  // ใช้ description ของ productmenu
      images: productmenu.images,  // ใช้ images ของ productmenu
      price: productmenu.discountedPrice || productmenu.price, // ใช้ราคาที่ลดหรือราคาเดิมของ productmenu
      count: 1,
      isProductMenu: true, // ระบุว่าเป็น productmenu
    };
  
    cart.push(item);
  
    // ทำให้สินค้าในตะกร้าไม่ซ้ำกัน
    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem("cart", JSON.stringify(unique));
  
    // เพิ่มข้อมูลใน Redux store
    dispatch({
      type: "ADD_TO_CART",
      payload: unique,
    });
    console.log("Dispatching ADD_TO_CART with:", unique);
  
    dispatch({
      type: "SET_VISIBLE",
      payload: true,
    });
  };
  
  
  if (loading) return <div>Loading...</div>;

  return (
    <div className="product-details-container">
      <p
        className="text-center my-4"
        style={{ fontFamily: "Kanit", color: "#FF5733", fontSize: "24px" }}
      >
        Products
      </p>
      <div className="row justify-content-center">
        <div className="col-md-5">
          {products.map((product) => (
            <div key={product._id} className="card mb-4" style={{ width: "400px" }}>
              <img
                src={product.images[0]}
                className="card-img-top img-fluid"
                alt={product.name}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">
                  {product.description ? product.description.slice(0, 50) : "No description available"}...
                </p>
                {/* Display prices */}
                <div className="product-prices">
                  <h6 style={{ color: "#28a745" }}>
                    ราคา: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(product.price)}
                  </h6>
                  {product.discountedPrice > 0 && (
                    <h6 style={{ textDecoration: 'line-through', color: '#dc3545' }}>
                      ราคาลด: {new Intl.NumberFormat('th-TH', { style: 'currency', currency: 'THB' }).format(product.discountedPrice)}
                    </h6>
                  )}
                </div>
                <div className="product-actions text-center">
                  <EyeOutlined
                    className="text-warning mx-3"
                    onClick={() => navigate(`/product/${product._id}`)}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  />
                  <ShoppingCartOutlined
                    className="text-danger mx-3"
                    onClick={() => handleAddToCart(product)}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="col-md-4">
          <h4 className="text-center custom-h4">Other Links</h4>
          <ul className="list-unstyled text-center">
            {websiteLink && (
              <li>
                <Link to={websiteLink} className="btn btn-primary mb-2">
                  Visit Website
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
