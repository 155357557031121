import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionDetails = async () => {
      const sessionId = new URLSearchParams(window.location.search).get("session_id");

      try {
        const { data } = await axios.get(`${process.env.REACT_APP_API}/success?session_id=${sessionId}`);

        // ตรวจสอบการชำระเงินสำเร็จ
        if (data.success) {
          toast.success("Payment Successful! Your order has been placed.");
          navigate("/user/history");
        } else {
          toast.error("Payment failed. Please try again.");
          navigate("/checkout");
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        toast.error("An error occurred while verifying your payment.");
        navigate("/checkout");
      }
    };

    fetchSessionDetails();
  }, [navigate]);

  return (
    <div>
      <h1>Processing your order...</h1>
    </div>
  );
};

export default Success;
