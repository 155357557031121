import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function FloatingLoginButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ใช้ optional chaining เพื่อหลีกเลี่ยงข้อผิดพลาดเมื่อ state.user เป็น null หรือ undefined
  const { token } = useSelector((state) => state.user || {});

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    !token && ( // ถ้าไม่มี token (ยังไม่ได้ล็อกอิน)
      <button
        onClick={handleLoginRedirect}
        style={{
          position: "fixed",
          bottom: "80px",
          right: "20px",
          padding: "10px 20px",
          backgroundColor: "#0a7f0a",
          color: "white",
          border: "none",
          borderRadius: "5px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        }}
      >
        เข้าสู่ระบบ
      </button>
    )
  );
}

export default FloatingLoginButton;
