import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import {
  HomeOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import Search from "../card/Search";
import "./NavbarHead.css";

import logo from "./img/logo-header.png";

const HeaderNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cart } = useSelector((state) => ({ ...state }));

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
  };

  return (
    <div className="New-container">
      {/* โลโก้ตรงกลาง */}
      <div className="logo-wrapper mx-auto">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo-image" />
        </Link>
      </div>

      {/* เมนูขวาสุด */}
      
    </div>
  );
};

export default HeaderNew;
