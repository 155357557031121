import React from "react";
import NewProduct from "../../home/NewProduct";
import Vitamins from "../../home/Vitamins";

import "./ShopProduct.css"
import Health from "../../home/Health";

const ShopProduct = () => {
  return (
    <div className="container">
      
      <div className="box-shadow mt-1">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12  custom-heading44-container">
            <div className="custom-heading blinking-boxss">
              {/* Vitamins & Supplements */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <Vitamins />
      </div>

      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading22-container">
            <div className="custom-heading blinking-boxss">
              {/* Health and Fitness */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <Health />
      </div>

      <div className="box-shadow mt-1">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading66-container">
            <div className="custom-heading blinking-boxss">
              {/* Beauty Product */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <NewProduct />
      </div>

      <div className="box-shadow mt-1">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading77-container">
            <div className="custom-heading blinking-boxss">
              {/* Organic Product */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <NewProduct />
      </div>
      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading11-container">
            <div className="custom-heading blinking-boxss">
              {/* Groceries */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <NewProduct />
      </div>
      
      <div className="box-shadow mt-5">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading33-container">
            <div className="custom-heading blinking-boxss">
              {/* Mom & Baby */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <NewProduct />
      </div>
      
      <div className="box-shadow mt-1">
        {/* Row div ที่ใช้ข้อความ "Rehabilitation" บนภาพ */}
        <div className="row mt-1">
          <div className="col-12 custom-heading55-container">
            <div className="custom-heading blinking-boxss">
              {/* Medical and Ambulantory Supplies */}
            </div>
          </div>
        </div>
        {/* ส่วนของสินค้าหรือคอนเทนต์อื่น ๆ */}
        <NewProduct />
      </div>
      
      
      
    </div>
  );
};

export default ShopProduct;
