import React, { useState, useEffect } from "react";
import { listProductByCategoryService } from "../function/categoryservice"; // นำเข้าฟังก์ชันใหม่ที่ดึงสินค้าจากหมวดหมู่
import ProductCardHome from "../card/ProductCardHome";
import LoadingCard from "../card/LoadingCard";
import "./YooEnCare.css";
const HotUniqare = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  // กำหนด ID ของหมวดหมู่สินค้าบริการที่ต้องการดึง
  const categoryId = "66c5a9fff9f03b44e0978a2b"; // ใส่ ID ของหมวดหมู่สินค้าบริการที่ต้องการ

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    // ดึงข้อมูลสินค้าโดยใช้หมวดหมู่
    listProductByCategoryService(categoryId, 8)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="container best-seller-container">
      {loading ? (
        <LoadingCard count={3} />
      ) : (
        <div className="horizontal-scroll-container">
          <div className="horizontal-scroll">
          {products.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    products.length <= 6
                      ? "col-3 mx-auto"
                      : "col-5 col-lg-1 mx-auto"
                  } mb-4`}
                >
                  <ProductCardHome product={item} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HotUniqare


