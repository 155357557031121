import React, { useState, useEffect } from "react";
import { listProductBy } from "../function/product";
import ProductCardHome from "../card/ProductCardHome";
import LoadingCard from "../card/LoadingCard";
import "./NewProduct.css";

const BestSeller = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    listProductBy("sold", "desc", 8) // Fetch more products for horizontal scroll
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="container home-container">
      {loading ? (
        <LoadingCard count={3} />
      ) : (
        <div className="home-scroll-container">
          {products.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    products.length <= 6
                      ? "col-3 mx-auto"
                      : "col-5 col-md-3 mx-auto"
                  } mb-4`}
                >
                  <ProductCardHome product={item} />
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default BestSeller;
