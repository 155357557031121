import React from 'react';
import { Link } from 'react-router-dom';
import './MenubarUserHomeNew.css';
// import rehabImage from './MenuUser/Rehab2.1_0.png';
// import medicalspa from './MenuUser/Medi2.1_0.png';
// import aesthetics from './MenuUser/Aes2.1_0.png';
// import antiaging from './MenuUser/Anti2.1.2_0.png';
// import hotelsbooking from './MenuUser/Hotel2.1.png';
// import restaurants from './MenuUser/Rest2.1.2_0.png';
// import sportclub from './MenuUser/Sport2.1_0.png';
// import massagespa from './MenuUser/Mass2.1_0.png';
// import beautysalon from './MenuUser/Salon2.1_0.png';
// import wellnesstourism from './MenuUser/Wellness2.1_0.png';
import supplement from '../page/images/logoService/Supplement.png';
import rehabImage from '../page/images/logoService/Rehabilitation.png';
import aesthetics from '../page/images/logoService/Aesthetic.png';
import antiaging from '../page/images/logoService/Anti-Aging.png';
import healcheck from '../page/images/logoService/HealthCheck-up.png';
import hotelsbooking from './MenuUser/Hotel2.1.png';
import restaurants from './MenuUser/Rest2.1.2_0.png';
import sportclub from './MenuUser/Sport2.1_0.png';
import massagespa from '../page/images/logoService/MassageANDspa.png';
import beautysalon from '../page/images/logoService/Beauty.png';
import wellnesstourism from '../page/images/logoService/WellNess.png';
import fitness from '../page/images/logoService/Fitness.png';
import mom from '../page/images/logoService/MomAndBaby.png';

const MenubarUserHomeNew = () => {
  const categories = [
    { name: 'Supplement', id: '66f7a06eacfb993d2ef594a4', image: supplement, categoryType: '360' },
    { name: 'Rehabilitation', id: '66c5a9fff9f03b44e0978a2b', image: rehabImage, categoryType: 'service' },
    { name: 'Aesthetics', id: '675c1542814f4f6f175fe8ab', image: aesthetics, categoryType: 'service' },
    { name: 'Anti-aging', id: '675c16a50c54f19b1d97a397', image: antiaging, categoryType: 'service' }, // เปลี่ยน id
    { name: 'HealthCheck', id: '666d097b25462c519c2685fe', image: healcheck, categoryType: 'service' },
    { name: 'Massagespa', id: '666d205a25462c519c268653', image: massagespa, categoryType: '360' }, // เปลี่ยน id
    { name: 'Beautysalon', id: '676e4ad6f6b0e027bfdddad1', image: beautysalon, categoryType: '360' }, // เปลี่ยน id
    { name: 'Wellnesstourism', id: '673eb4ec79ca2e463fc91d80', image: wellnesstourism, categoryType: '360' }, // เปลี่ยน id
    { name: 'Fitness', id: '67592e56dc7fc5f46a356688', image: fitness, categoryType: '360' }, // เปลี่ยน id
    { name: 'Mom&Baby', id: '6768f5750c54f19b1d97e29b', image: mom, categoryType: '360' }, // เปลี่ยน id
 
  
    // { name: 'Hotels booking', id: '66c5934ef9f03b44e0978625', image: hotelsbooking },
    // { name: 'Restaurants', id: '66c5934ef9f03b44e0978625', image: restaurants },
    // { name: 'Sportclub', id: '66c5934ef9f03b44e0978625', image: sportclub }, 
  ];

  const categoriesService = [
    
  ]

  const formatCategory = (category) => {
    const categoryMap = {
      'Supplement': 'supplement-for-health',
      'Rehabilitation': 'rehabilitation',
      'Aesthetics': 'aesthetics',
      'Anti-aging': 'anti-aging',
      'HealthCheck': 'health-check',
      'Massagespa': 'massagespa',
      'Beautysalon': 'beautysalon',
      'Wellnesstourism': 'wellness-tourism',
      'Fitness': 'fitness-performance',
      'Mom&Baby': 'mom-and-baby',
    };
    return categoryMap[category] || category.toLowerCase().replace(/ /g, '-');
  };

  return (
    <div className="container">
      <div className="row mt-3 category-row">
        {categories.map((category) => (
          <div key={category.id} className="col-3 col-md-2 mb-3 category-card-container">
            <Link
              to={`/shop-origi?category=${formatCategory(category.name)}&categoryType=${category.categoryType}`}
            >
              <div
                className="category-card"
                style={{ backgroundImage: `url(${category.image})` }}
              >
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};


export default MenubarUserHomeNew;
