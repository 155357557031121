// ResetPassword.js
import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import logo from "./logo/logo.png";
import { Spin } from "antd";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      toast.error("Passwords do not match.");
      return;
    }
    console.log("Sending reset password request with token:", token, "and password:", password);
    try {
      // ส่งข้อมูลใน request body
      const response = await axios.post(
        `${process.env.REACT_APP_API}/reset-password`,
        { token, password } // ส่งข้อมูลแบบนี้
      );
      toast.success("Password has been reset successfully.");
      navigate("/login");
    } catch (err) {
      toast.error("Failed to reset password.");
      console.error(err);
    }
  };


  return (
    <div className="container p-5">
      <div className="row justify-content-center">
        <div className="col-md-6" style={{
          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
          padding: "2rem",
          borderRadius: "8px",
        }}>
          {loading ? (
            <h1>
              Loading...
              <Spin />
            </h1>
          ) : (
            <div className="text-center mb-4">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ maxWidth: "200px" }}
              />
              <h4>รีเซ็ตรหัสผ่านของคุณ</h4>
            </div>
          )}
          
          <form onSubmit={handleSubmit}>
          <p
          style={{
            fontSize: "16px", // ขนาดฟอนต์
            color: "#424141", // สีของข้อความ
            textAlign: "left", // ชิดซ้าย
            lineHeight: "1.6", // ระยะห่างระหว่างบรรทัด
           
          }}>
            ตั้งรหัสผ่านใหม่</p>
            <input
              className="form-control"
              type="password"
              placeholder="Enter new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              className="form-control"
              type="password"
              placeholder="Confirm new password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <button 
            className="btn mt-3"
            style={{ backgroundColor: "#4CB4F6", color: "#fff" }}
            type="submit">Reset Password</button>
          </form>
        </div>
      </div>

    </div>

  );
};

export default ResetPassword;
