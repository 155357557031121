import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FileUploadProfile from "./FileUploadProfile";
import { updateProfile } from "../../../function/users";

const ManageProfile = () => {
  const { user } = useSelector((state) => ({ ...state }));
  const [profileData, setProfileData] = useState({
    
    address: user?.address?.name || "",
   
    image: user?.profileImage?.url || "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("User from Redux:", user); // ตรวจสอบค่าของ user
  }, [user]);

  // ฟังก์ชันสำหรับอัปเดตรูปโปรไฟล์
  const handleSetProfileImage = (image) => {
    setProfileData((prevData) => ({ ...prevData, image })); // อัปเดตสถานะรูปโปรไฟล์
  };

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    if (!user || !user._id) {
      toast.error("User information is missing. Please log in again.");
      setLoading(false);
      return;
    }
  
    try {
      // สร้าง FormData
      const formData = new FormData();
      if (profileData.image) {
        formData.append("profileImage", profileData.image);
      }
  
      // อัปเดตโปรไฟล์
      await updateProfile(user.token, user._id, formData);
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile image:", error);
      toast.error("Failed to update profile image.");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  

  return (
    <div className="container">
      <h4>Manage Profile</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Username</label>
          <p className="form-control-static">{profileData.username}</p>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            className="form-control"
            value={profileData.email}
            onChange={handleChange}
            disabled
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            className="form-control"
            value={profileData.phone}
            onChange={handleChange}
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label>Profile Image</label>
          <FileUploadProfile
            profileImage={profileData.image} // ส่งรูปโปรไฟล์ปัจจุบัน
            setProfileImage={handleSetProfileImage} // ส่งฟังก์ชันสำหรับอัปเดตรูปโปรไฟล์
            loading={loading}
            setLoading={setLoading}
          />
        </div>
        <button className="btn btn-primary" type="submit" disabled={loading}>
          Update Profile
        </button>
      </form>
    </div>
  );
};

export default ManageProfile;


