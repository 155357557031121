import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineShop, AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import "./BottomNav.css";
function BottomNav() {
  const navigate = useNavigate();

  return (
    <div className="bottom-nav" style={styles.navContainer}>
    <button style={styles.navButton} onClick={() => navigate("/")}>
      <AiOutlineHome size={24} />
      <span>Home</span>
    </button>
    <button style={styles.navButton} onClick={() => navigate("/shop")}>
      <AiOutlineShop size={24} />
      <span>Shop</span>
    </button>
    <button style={styles.navButton} onClick={() => navigate("/cart")}>
      <AiOutlineShoppingCart size={24} />
      <span>Cart</span>
    </button>
    <button style={styles.navButton} onClick={() => navigate("/profile")}>
      <AiOutlineUser size={24} />
      <span>Profile</span>
    </button>
  </div>
  );
}

const styles = {
  navContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-around",
    backgroundColor: "#fff",
    borderTop: "1px solid #ddd",
    padding: "10px 0",
    zIndex: 1000,
  },
  navButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "12px",
    color: "#555",
    background: "none",
    border: "none",
    cursor: "pointer",
  },
};

export default BottomNav;
