import React from 'react';
import { Navbar, Nav, NavDropdown, Badge, Container } from 'react-bootstrap';  // เพิ่ม Container ที่นี่
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './ResponsiveNavbar.css'; // เพิ่มไฟล์ CSS
import logo from "./img/logo192.png";
import Search from "../card/Search";
import {
  HomeOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  ScheduleOutlined,
  GiftOutlined,
  AppstoreAddOutlined,
  SearchOutlined 
} from "@ant-design/icons";

const ResponsiveNavbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cart } = useSelector((state) => ({ ...state }));

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
  };

  return (
    <Navbar  expand="lg" className="navbar-custom">
      <Container>  {/* ใช้ Container เพื่อจัดระเบียบการแสดงผล */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          {/* <Link to="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "40px",
                height: "40px",
                marginRight: "10px",
                marginLeft: "40px",
              }}
            />
          </Link> */}
        </Navbar.Brand>

        {/* ปรับปุ่ม Toggle ให้แสดง 3 ขีดและข้อความ MENU */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-navbar-toggle">
          <span className="menu">MENU</span> {/* เพิ่มข้อความ MENU */}
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/shop">Shop</Nav.Link>
            {/* <Nav.Link as={Link} to="/shop-product">Product</Nav.Link>
            <Nav.Link as={Link} to="/shop-services">Services</Nav.Link> */}
            <Nav.Link href="#groceries">Hot Deals</Nav.Link>
            <Nav.Link href="#health">Best Sellers</Nav.Link>
            <Nav.Link href="#vitamins">New Arrivals</Nav.Link>
            <Nav.Link href="#vitamins">Brands</Nav.Link>
            <Nav.Link as={Link} to="/about">About us</Nav.Link>
            <Nav.Link as={Link} to="/partner">Partner Center</Nav.Link>
          </Nav>

          <Nav className="ms-auto">
          
            
            <Search />
            
              
            
            <Nav.Link as={Link} to="/cart" className="flex-fill text-center">
              <ShoppingCartOutlined />
              <Badge pill bg="secondary" className="ms-1">
                {cart.length}
              </Badge>
              
              
              ตะกร้าสินค้า
            </Nav.Link>
            


            {user ? (
              <NavDropdown
                title={user.username}
                id="basic-nav-dropdown"
                className="user-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to={user.role === "admin" ? "/admin/index" : "/user/index"}
                >
                  <AppstoreAddOutlined /> Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/shop"
                >
                  <ShoppingOutlined /> ร้านค้า
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link} to="/user/order-tracking"
                >
                 <ScheduleOutlined /> ติดตามคำสั่งซื้อ
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link} to="/promotion-user"
                >
                  <GiftOutlined /> โปรโมชั่น
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout}>
                  <LogoutOutlined /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <>
                <Nav.Link as={Link} to="/login" className="flex-fill text-center">
                  <LoginOutlined /> Login
                </Nav.Link>
                <Nav.Link as={Link} to="/register" className="flex-fill text-center">
                  <UserAddOutlined /> Register
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ResponsiveNavbar;
