import React from 'react'
import Search from '../card/Search'
const LocationShop = () => {
  return (
    <>
    <div className='container'>
      <div className='row'>
         <div className='col-md-3'>พื้นที่ของคุณคือ</div>
         <div className='col-md-7'>
            <Search/>
         </div>
      </div>
      
    </div>
    
    </>
    )
}

export default LocationShop
