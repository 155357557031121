import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partner.css'; // นำเข้าไฟล์ CSS ของคุณ

const About = () => {
  

  return (
    <div className='container'>
      <h2 className='text-center'>about us</h2>
      
    </div>
  );
}

export default About;
